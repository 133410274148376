<template>
    <div class="page talron main-page">
        <layout-blob>
            <div class="content">
                <div class="battery-image-wrap">
                    <img :src="getDynamicAssetUrl('images/battery.webp')" alt="" />
                </div>

                <div class="success-ui uk-flex uk-flex-column uk-flex-middle uk-flex-center" v-if="successMode">
                    <span class="uk-text-success talron-replace-success-icon " uk-icon="check-circle"></span>
                    <span class="tr-font-l uk-text-bold uk-text-success">{{translate('talron.battery.talronReplaceSuccess')}}</span>
                </div>

                <div class="warranty-title-success" v-if="successMode">
                    <span v-if="!batteryInfo.warranty.isExpired"  class="uk-text-bold">{{ translate('talron.battery.warrantyStateTitle')}}</span>
                    <span v-else >{{ translate('talron.battery.warrantyStateEnd')}}</span>
                    <span>{{warrantyEndDateReadable}}</span>
                </div>

                <div class="warranty-title" v-if=" ! successMode">
                    <div class="title-text">
                        <h1 v-if="!batteryInfo.warranty.isExpired" aria-live="assertive" tabindex="0"
                            class="uk-text-bold tr-font-m uk-margin-remove">{{ translate('talron.battery.warrantyStateTitle')}}</h1>
                        <span v-else >{{ translate('talron.battery.warrantyStateEnd')}}</span>

                    </div>
                    <div class="warranty-end-date">
                        <span>{{warrantyEndDateReadable}}</span>
                    </div>
                </div>

                <div class="progress-bar-wrapper"
                     :aria-label="translate('talron.battery.ariaWarranty', {start:batteryInfo.warranty.fromDate, end:batteryInfo.warranty.toDate})"
                     aria-hidden="true">

                    <progress-bar
                        label="talron.demo.warrantyDuration"
                        :start-value="batteryInfo.warranty.fromTime"
                        :current-value="unixTimestamp"
                        :end-value="batteryInfo.warranty.toTime"
                        :start-label="batteryInfo.warranty.fromDate"
                        :end-label="batteryInfo.warranty.toDate"></progress-bar>

                </div>
                <div class="sr-only">
                    {{translate('talron.battery.ariaWarranty', {start:batteryInfo.warranty.fromDate, end:batteryInfo.warranty.toDate})}}
                </div>
                <battery-details
                    v-if=" ! isHeatSignature"
                    :battery-info="batteryInfo"
                    :expand="false"
                    class="uk-hidden@l"
                ></battery-details>

                <!-- current logic: when in details mode, show info if in warranty, or if not head signature -->
                <div class="actions" v-if="hasUser">
                    <div class="title-separator-wrap"
                         :aria-label="translate('talron.battery.batteryActionsTitle')"
                         v-if="! successMode &&( ! isHeatSignature || isInWarranty) ">
                        <title-separator theme="dark" text="talron.battery.batteryActionsTitle">
                            <h2 class="uk-text-bold tr-font-m uk-margin-remove" style="line-height: 1;">
                                {{ translate('talron.battery.batteryActionsTitle') }}
                            </h2>
                        </title-separator>
                    </div>
                    <action-button-group v-if=" ! successMode">
                        <action-button
                            icon="battery"
                            role="link"
                            tabindex="0"
                            @click="startReplaceTalronProcess"
                            v-show="isInWarranty && $store.getters['talron/userCan']('replaceBatteryTalron')"
                            text="">
                            {{safeTranslate('talron.battery.ctaReplaceTalron')}}
                        </action-button>
                        <action-button
                            v-show="isInWarranty && $store.getters['talron/userCan']('replaceBatteryExternal')"
                            :navAction="{name: 'replace-external'}"
                            icon="swap-arrows"
                            role="link"
                            tabindex="0">
                            {{safeTranslate('talron.battery.ctaReplaceOther')}}
                        </action-button>
                        <action-button
                            @click="startRefreshBatteryProcess"
                            v-if=" $store.getters['talron/userCan']('refreshBatteryWarranty')"
                            icon="reload"
                            role="link"
                            tabindex="0">
                            {{safeTranslate('talron.battery.ctaRefresh')}}
                        </action-button>
                    </action-button-group>
                </div>


                <div class="desktop-battery-details">
                    <battery-details
                        v-if=" ! isHeatSignature"
                        :battery-info="batteryInfo"
                        :expand="true"
                        class="uk-visible@l"
                    ></battery-details>
                </div>

                <button-group class="uk-width-expand uk-flex uk-flex-center uk-margin-top" v-if="hasUser">
                    <form-button @click="$router.push({name: 'index'});">
                        {{translate('talron.battery.backToMainPage')}}
                    </form-button>
                </button-group>

                <button-group class="uk-width-expand uk-flex uk-flex-center uk-margin-top" v-else>
                    <form-button @click="$router.push({name: 'contact'});">
                        {{translate('talron.battery.publicHelpCta')}}
                    </form-button>
                </button-group>
               <modal :show="showRefreshSuccessModal"
                      @modal:closed="showRefreshSuccessModal = false"
                      :subject="translate('talron.battery.refreshSuccessAria')">
                    <template #default>
                    <div class="image-block uk-flex uk-flex-center" style="margin-bottom: 8px;">
                        <img :src="getDynamicAssetUrl('images/battery-success.svg')" alt=""  style="height: 47px; width: auto;margin: 0 auto;"/>
                    </div>
                    <div class="message uk-text-center">
                        <h3 class="tr-font-title uk-text-success " style="margin-bottom: 6px;">
                            {{translate('talron.batteryRefresh.successTitle')}}
                        </h3>
                        <span class="tr-font-small uk-text-success">
                            {{translate('talron.batteryRefresh.successBatteryId')}} : {{batteryInfo.serialNumber}}
                        </span>
                    </div>
                </template>
                    <template #footer>
                        <div class="footer-inner">
                           <button class="uk-button uk-button-primary"
                                   type="button"
                                   @click.prevent="showRefreshSuccessModal = false, $router.push({name: 'index'})">{{translate('talron.batteryRefresh.refreshFinishCtaAnother')}}</button>
                           <div class="link-container ">
                               <a href="#" @click.prevent="showRefreshSuccessModal = false, $router.push({name: 'index'})"
                                  class="uk-text-bold" style="text-decoration: underline; ">{{translate('talron.batteryRefresh.refreshFinishCtaBackHome')}}</a>
                           </div>
                       </div>
                    </template>
               </modal>

               <modal :show="showRefreshFailureModal"
                      :subject="translate('talron.battery.refreshFailAria')"
                      @modal:closed="showRefreshFailureModal = false">
                    <template #default>
                        <div class="image-block uk-flex uk-flex-center" style="margin-bottom: 8px;">
                            <img :src="getDynamicAssetUrl('images/battery-success.svg')" alt=""  style="height: 47px; width: auto;margin: 0 auto;"/>
                        </div>
                        <div class="message uk-text-center">
                            <h3 class="tr-font-title " style="color: var(--modal-error-color); margin-bottom: 6px;">
                                {{translate('talron.batteryRefresh.failureTitle')}}
                            </h3>
                            <span class="tr-font-small " style="color: var(--modal-error-color);">
                            {{translate('talron.batteryRefresh.failureBatteryId')}} : {{batteryInfo.serialNumber}}
                        </span>

                        </div>
                        <p class="message explain uk-text-center" v-if="overrideRefreshFailText">
                            {{overrideRefreshFailText}}
                        </p>
                        <p class="message explain uk-text-center" v-else>
                            {{translate('talron.batteryRefresh.refreshFailureExplain', {phoneNumber: $store.getters['talron/contactNumberReadable'] })}}
                        </p>
                    </template>
                    <template #footer>
                        <div class="footer-inner">
                            <a class="uk-button uk-button-primary"
                                    type="button"
                               :href="'tel:'+$store.getters['talron/contactNumber']"
                                    @click="showRefreshFailureModal = false"
                                    >
                                <span>{{translate('talron.batteryRefresh.refreshFinishErrorCtaContact') + ' '}}</span><span style="direction:ltr;text-align:left;unicode-bidi: embed">{{$store.getters['talron/contactNumberReadable']}}</span>

                             </a>
                            <div class="link-container ">
                                <a href="#" @click.prevent="showRefreshFailureModal = false, $router.push({name: 'index'})"
                                   class="uk-text-bold" style="text-decoration: underline; ">{{translate('talron.batteryRefresh.refreshFinishCtaBackHome')}}</a>
                            </div>
                        </div>
                    </template>
                </modal>
            </div>
        </layout-blob>


    </div>
</template>

<script>

import BatteryDetails from '@/client/applications/talron/components/talron/BatteryDetails.vue';
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import {useStore, mapGetters} from "vuex";
import {computed} from "vue";

export default {

    props: {
        successMode: {
            type: Boolean,
            default: false,
        }
    },

    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        return {asyncOps, asyncOpsReady, asyncStatus};
    },

    name:  'battery-view',

    data() {
        return {
            showRefreshSuccessModal: false,
            showRefreshFailureModal: false,
            detailsExpanded: false,
            unixTimestamp: Math.floor(Date.now() / 1000),
            asyncData: {},
            overrideRefreshFailText : false,
        }
    },
    components: {
        BatteryDetails
    },
    computed  : {
        ...mapGetters({
              'hasUser'       :'talron/hasUser',
              'userName'      : 'talron/userName',
              'userId'        : 'talron/userId',
              'userPersonalId': 'talron/userPersonalId',
              'hasBattery'    : 'talron/hasBattery',
              'batteryInfo'   : 'talron/battery',
              'isInWarranty'  : 'talron/isBatteryWarrantyValid',
              'isHeatSignature' :'talron/isBatteryWithHeatSignature'
          }),
        warrantyEndDateReadable() {
            return this.batteryInfo.warranty.toDate;
        }
    },
    methods   : {
        async startRefreshBatteryProcess () {
            this.$store.commit('ui/showGlobalSpinner', 'talron.general.loadingSpinnerText')

            let result = await this.asyncOps.asyncCall(
                'battery/refresh',
                {batteryBarcode : this.batteryInfo.serialNumber, userId: this.userId},
                {method: 'patch'}
            );

            this.$store.commit('ui/hideGlobalSpinner')

            if (result.data.status === '1' || result.data.status === 1) {
                delete result.data.status;
                this.handleRefreshSuccess(result.data); // extend this with fetching new battery status, if needed in the future
            } else {
                console.log('refresh fail', result.data);
                this.overrideRefreshFailText = (result.data.rejectionReason && result.data.rejectionReason !== '') ? result.data.rejectionReason : false
                //overrideRefreshFailText
                this.showRefreshFailureModal = true;
            }
        },
        handleRefreshSuccess (data) {
            this.$store.commit('talron/appActionState', 'success');
            this.$store.commit('talron/setBatteryWarrantyFields', data);
            this.showRefreshSuccessModal = true;
        },
        async startReplaceTalronProcess() {
            this.$store.commit('ui/showGlobalSpinner', 'talron.general.loadingSpinnerTextShort');

            let result = await this.asyncOps.asyncCall(
                'battery/replace/talron',
                {batteryBarcode : this.batteryInfo.serialNumber, userId: this.userId},
                {method: 'post'}
            );

            this.$store.commit('ui/hideGlobalSpinner')

            if (result.data.status !== '1' && result.data.status !== 1) {
                let errorText =  typeof result.data.rejectionReason === 'string' ? result.data.rejectionReason : this.translate('talron.replace.talron.batteryCanNotBeReplaced');
                this.$s.ui.notification(errorText, 'error');
                return;
            }

            this.$store.commit('talron/replacementGUID', result.data.GUID);
            this.$router.push({name: 'replace-talron'});
        }
    },
}
</script>

<style lang="scss" scoped>
.content {
    position: relative;
}

.battery-image-wrap {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 1200px) {
        top: -120px;
    }

    img {
        height: 86px;
        width: auto;
    }
}

.success-ui {

}
.warranty-title {
    text-align: center;
    margin-top: 15px;

    @media (min-width: 1200px) {
        margin-top: 0px;
    }

    .title-text {

    }

    .warranty-end-date {
        font-size: 26px;
        font-weight: bold;

        @media (min-width: 1200px) {
            margin-top: 5px;
        }
    }
}

.talron-replace-success-icon {
    margin-bottom: 8px;
}

.warranty-title-success {
    font-size: 20px;
    font-weight: 900;
    width: 100%;
    text-align: center;
    margin-bottom: 2px;
}
.progress-bar-wrapper {
    margin: 15px auto 15px;

    @media (min-width: 1200px) {
        margin-top: 10px;
    }
}

.title-separator-wrap {
    margin: 40px auto 20px auto;

    @media (min-width: 1200px) {
        margin-top: 10px;
    }
}

.desktop-battery-details {
    margin-top: 30px;
    margin-bottom: 5px;
}

.details-title {
    cursor: pointer;
    padding: 10px 18px;
}

.detail {
    padding: 10px 18px;

    &:nth-child(odd) {
        background-color: var(--global-muted-background);
    }

}

</style>

<style lang="scss">

.title-separator .content-text {
   // color: var(--global-background)!important;
}
</style>




